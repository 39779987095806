import barba from '@barba/core';
import gsap from 'gsap';

const BARBA_STATUS_BEFORE       = 'before';
const BARBA_STATUS_BEFORE_LEAVE = 'beforeLeave';
const BARBA_STATUS_LEAVE        = 'leave';
const BARBA_STATUS_AFTER_LEAVE  = 'afterLeave';
const BARBA_STATUS_BEFORE_ENTER = 'beforeEnter';
const BARBA_STATUS_ENTER        = 'enter';
const BARBA_STATUS_AFTER_ENTER  = 'afterEnter';
const BARBA_STATUS_AFTER        = 'after';

if (history.scrollRestoration) {
    history.scrollRestoration = 'manual';
}

document.addEventListener('DOMContentLoaded', function () {
    const transitionEl = document.querySelector('#page-transition');
    let offsetHeight = 0;

    // @NOTE
    // For more information about configuration / setup and possibilities, see https://barba.js.org/docs/getstarted/intro/
    barba.init({
        debug: false,
        logLevel: 'error',
        cacheIgnore: false,
        prefetchIgnore: true,
        preventRunning: true,
        timeout: 15000,
        prevent: ({el}) => el.classList && el.classList.contains('barba-prevent'),
        requestError: (trigger, action, url, response) => {
            // go to a custom 404 page if the user click on a link that return a 404 response status
            // if (action === 'click' && response.status && response.status === 404) {
            //     return true;
            // }

            // @NOTE
            // Prevent Barba from redirecting the user to the requested URL
            // This is equivalent to e.preventDefault() in this context
            return false;
        },
        transitions: [
            {
                name: 'simple',
                // @NOTE
                // The "to" key is to set a rule to know when this animation should run or not based on the next page namespace.
                to: {
                    namespace: [
                        'simple',
                        'park-single'
                    ]
                },
                leave(data) {
                    return new Promise((resolve) => {
                        let tl = gsap.timeline({
                            autoRemoveChildren: true,
                            defaults: { ease: 'power1.out' },
                            onComplete: () => {
                                resolve();
                            }
                        });
                        tl.to(transitionEl.querySelector('.pt-mountain:nth-child(1)'), { y: '-130vh', duration: 1.2 });
                        tl.to(transitionEl.querySelector('.pt-mountain:nth-child(2)'), { y: '-130vh', duration: 1.1 }, 0.1);
                        tl.to(transitionEl.querySelector('.pt-mountain:nth-child(3)'), { y: '-130vh', duration: 1 }, 0.2);
                    });
                },
                beforeEnter(data) {
                    if (window.lenis) {
                        window.lenis.scrollTo(0, {
                            immediate: true,
                            lock: true,
                            force: true
                        });
                    }
                    else {
                        window.scrollTo({
                            top: 0,
                            left: 0,
                            behavior: 'instant',
                        });
                    }
                },
                enter(data) {
                    data.next.container.classList.add('transitionning');
                    gsap.set('.transitionning .fade-in', { opacity: 0 });
                    gsap.set(data.current.container, { height: 0, opacity: 0 });

                    if(data.next.url.path.includes('faq') && data.next.url.hash){
                        let targetPosition = data.next.url.hash ? document.querySelector('#' + data.next.url.hash).getBoundingClientRect().top - document.querySelector('.primary-navigation').offsetHeight : 0;
                        if (targetPosition) {
                            if (window.lenis) {
                                window.lenis.scrollTo(targetPosition, {
                                    immediate: true,
                                    lock: true,
                                    force: true
                                });
                            }
                            else {
                                window.scrollTo({
                                    top: targetPosition,
                                    left: 0,
                                    behavior: 'instant',
                                });
                            }
                        }

                        let currentCategory = document.querySelector('#' + data.next.url.hash);
                        currentCategory.querySelector('.accordion').classList.add('active');
                        currentCategory.querySelector('.accordion').classList.add('always_open');
                    }

                    return new Promise((resolve) => {
                        let tl = gsap.timeline({
                            autoRemoveChildren: true,
                            defaults: { ease: 'power1.in' },
                            onComplete: () => {
                                gsap.set(transitionEl.querySelectorAll('.pt-mountain'), { y: 0 });
                                resolve();
                            }
                        });
                        tl.to(transitionEl.querySelector('.pt-mountain:nth-child(3)'), { y: '-280vh', duration: 1.2 });
                        tl.to(transitionEl.querySelector('.pt-mountain:nth-child(2)'), { y: '-280vh', duration: 1.2 }, 0.05);
                        tl.to(transitionEl.querySelector('.pt-mountain:nth-child(1)'), { y: '-280vh', duration: 1.2 }, 0.1);
                    });
                }
            },
            {
                name: 'park',
                from: {
                    namespace: [
                        'park-single'
                    ]
                },
                to: {
                    namespace: [
                        'park-single'
                    ]
                },
                beforeLeave(data) {
                    offsetHeight = data.current.container.classList[0] === data.next.container.classList[0] ? document.querySelector('.hero').offsetHeight : 0;
                },
                leave(data) {
                    return gsap.to(data.current.container, {
                        opacity: 0,
                        onComplete: function(){
                            data.current.container.classList.add('hide');
                        }
                    });
                },
                beforeEnter(data) {
                    if (window.lenis) {
                        window.lenis.scrollTo(offsetHeight, {
                            immediate: true,
                            lock: true,
                            force: true,
                        });
                    }
                    else {
                        window.scrollTo({
                            top: offsetHeight,
                            left: 0,
                            behavior: 'instant',
                        });
                    }
                },
                enter(data) {
                    data.next.container.classList.add('transitionning');
                    gsap.set('.transitionning .fade-in', { opacity: 0 });

                    return gsap.from(data.next.container, {
                        opacity: 0
                    });
                }
            }
        ]
    });
    barba.hooks.before((data)=>{
        document.querySelector('html').classList.add('in-transition');
    });

    barba.hooks.afterLeave((data) => {
        // @NOTE
        // Get DOM of next and current page to compare the list of script found in the page to add or to remove them.
        const currentDOM = new DOMParser().parseFromString(data.current.html, 'text/html');
        const nextDOM = new DOMParser().parseFromString(data.next.html, 'text/html');
        const currentArrayScript = Array.from(currentDOM.querySelectorAll('body script'));
        const nextArrayScript = Array.from(nextDOM.querySelectorAll('body script'));

        //@NOTE
        // Parse all scripts from the next page to detect if they doesn't exit in the current page to add them
        let scriptNodeToAdd = [];
        let scriptNodeToRemove = [];
        let scriptRemoved = 0;
        let scriptLoaded = 0;

        nextArrayScript.forEach((nScript, nIndex) => {
            if (nScript.attributes && nScript.attributes.src) {
                let nScriptDom = document.querySelector(`script[src="${nScript.attributes.src.value}"]`)

                if (!nScriptDom) {
                    // Append script
                    let scriptNode = document.createElement('script');
                    scriptNode.async = nScript.attributes.async;
                    scriptNode.src = nScript.attributes.src.value;
                    scriptNodeToAdd.push(scriptNode)
                }
            }
        });

        //@NOTE Parse all scripts from the current page to detect if they doesn't exit in the current page to remove them
        currentArrayScript.forEach((cScript, nIndex) => {
            if (cScript.attributes && cScript.attributes.src) {
                let cSprintDom = nextDOM.querySelector(`script[src="${cScript.attributes.src.value}"]`);

                if (!cSprintDom) {
                    // Delete script
                    scriptNodeToRemove.push(document.querySelector(`script[src="${cScript.attributes.src.value}"]`));
                }
            }
        });

        // @NOTE
        // Create two promise for add and remove to be sure all scripts will be loaded or removed and then we can go to the next state of barba
        let addScripts = new Promise((resolve, reject) => {
            if (scriptNodeToAdd.length > 0) {
                scriptNodeToAdd.forEach((node, index) => {
                    document.querySelector('body').appendChild(node);
                    node.addEventListener('load', () => {
                        scriptLoaded++;

                        if (scriptLoaded === scriptNodeToAdd.length) {
                            resolve();
                        }
                    })
                });
            } else {
                resolve();
            }
        });

        let removeScripts = new Promise((resolve, reject) => {
            if (scriptNodeToRemove.length > 0) {
                scriptNodeToRemove.forEach((node, index) => {
                    try {
                        document.querySelector('body').removeChild(node);
                        scriptRemoved++;
                        if (scriptRemoved === scriptNodeToRemove.length) {
                            resolve();
                        }
                    } catch (error) {
                        // NotFoundError
                        resolve();
                    }
                });
            } else {
                resolve();
            }
        });

        // Return Promise since Barba works with promises. Won't go to the next state until all promises are done.
        return Promise.all([addScripts, removeScripts]);
    });

    barba.hooks.after((data) => {
        // @NOTE
        // Dispatch new page is now the current page
        // We init again all manage me views at the same time to restart and clear all events

        document.querySelector('.transitionning').classList.remove('transitionning');

        window.dispatchEvent(new CustomEvent("pageTransition", {
            detail: {
                status: BARBA_STATUS_AFTER
            }
        }));

        let homeHero = document.querySelector('.home.hero');
        if (homeHero) {
            let videos = homeHero.querySelectorAll('video');
            if(videos.length > 0) {
                videos.forEach(video => { let playPromise = video.play(); if (playPromise !== undefined) { playPromise.then(_ => {}).catch(error => {}); } });
            }
        }

        Me.manage.initViews();
        Me.formManager.initForms();
        window.dispatchEvent(new Event('resize'));
        document.querySelector('html').classList.remove('no-scroll');
        window.lenis.start();
    });
});
